import React from 'react';
import { SocketProvider } from '../context/SocketContext';
import Cookies from 'js-cookie';
import { Col, Row, Typography, List, Card,  Tag} from 'antd'
import { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../context/SocketContext';
import mongoose from 'mongoose';
function ListaEspera() {
     const [ number, setNumber] = useState(0)
     const   [ url, setUrl] = useState('')

 
        const { socket } = useContext(SocketContext);
        const ticketId = Cookies.get('ticketIdList');
        console.log(ticketId);
        
        // hacer llamada mongo
        // const ticketId = '5f9e8cbb9c8b9a1f0c4f3d3e';
    
        useEffect(() => {
          async function connectAndQuery() {
              try {
                  await mongoose.connect('mongodb+srv://hkCe1okUlF7mVYcb:EKAWApNGGW6GYhpi@serverlessinstance0.wbzefxz.mongodb.net/', {
                      useNewUrlParser: true,
                      useUnifiedTopology: true,
                  });
      
                  // Define el esquema y el modelo de tu colección
                  const userSchema = new mongoose.Schema({
                      url: String,
                      id: String,
                  });
                  const Ticket = mongoose.model('Ticket', userSchema);
      
                  // Realiza tu consulta aquí
                  // Por ejemplo:
                  const query = { id: ticketId };
                  const result = await Ticket.find(query);
                  console.log(result);
              } catch (error) {
                  console.error('Error connecting to MongoDB or executing query:', error);
              } 
          }
      
          connectAndQuery();
      }, []);
      socket.on('user-data',ticketId, (usuarios) => {
        // Obtener la longitud del array de usuarios
       console.log("data user")
        
        // Actualizar la lista de usuarios en el HTML 
      
      });
      
        // ...
        socket.emit('ticket-status',ticketId, ( ) => {
            socket.on('total-tickets', (count) => {
                // Realiza cualquier acción necesaria con el recuento de tickets recibido
                console.log('Recibido el recuento de tickets:', count);
                
                // Actualiza el valor del número de ticket
                setNumber(count);

                if (number <= 5) {
                  socket.emit('ticket-access', (data)=>{
                   
                    });
                    if(url){
                      window.location.href = url;}
                     
                    } else {
                        window.location.href = 'https://shop.selecta-vision.com';
                    }
               
                }
                 
             ); });
        

    

    
  return (
<>


<div className="container">
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: '30px' }}>
        <img src="https://www.selecta-vision.com/wp-content/uploads/logo-principal.svg" alt="" style={{ width: '26%' }} />
    </div>
    <h1 style={{ color: '#133361', textAlign: 'center', fontWeight: 200 }}>Lista de Espera</h1>
    <p style={{ lineHeight: '26px' }}>
        ¡Un momento, por favor! Ahora mismo hay muchos usuarios navegando en la página. Queremos brindarte un servicio de calidad y una experiencia excepcional, por eso hemos implementado esta lista de espera.
    </p>
    <p style={{ lineHeight: '26px' }}>
        Por favor, no salgas de esta ventana y en cuanto llegue tu turno serás redirigido a la página.
    </p>
</div>
 <div className="waitlist">
 <div style={{display: 'flex', flexDirection: 'row'}}>
     <div style={{backgroundColor: '#133361', width: '50%', padding:'0 20px 0 20px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>Hay {number} personas delante de ti.</div>
     <div style={{backgroundColor: '#133361', width: '50%', height: '300px'}}><img src="/img/my-hero-academia-computer.gif" style={{height: 'inherit'}} alt=""/></div>
 </div>
</div>
</>
  );
}

export default ListaEspera;
