import { useContext,useEffect, useState } from "react"
import { SocketContext } from "../context/SocketContext"

export const CrearTicket = () =>{

    const { socket } = useContext(SocketContext);
    const [ ticket, setTicket ] = useState(null)
    useEffect(() => {
        // si existe cookie 
        nuevoTicket(); // Ejecutar nuevoTicket() al cargar la página
      }, []);
    const nuevoTicket = () =>{
        var userIP = '';
        var browserInfo = '';
        
        // Obtener la IP del usuario
        fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {
            userIP = data.ip;
            
            // Obtener información del navegador
            browserInfo =  navigator.userAgent;
           
            const datas = { ip: userIP, browser: browserInfo };
          //  console.log(datas)
            // Enviar la IP y la información del navegador al servidor
            socket.emit('solicitar-ticket', datas, (ticket) => {
              setTicket(ticket)
              console.log(ticket)
              // redirigir a la home
                //window.location.href = '/';
            });
          });
    }
    return (
        <>
            <h1>Crear Ticket</h1>
            <p>{ticket && (
        <div>
          <p>ID: {ticket.id}</p>
          <p>Número: {ticket.number}</p>
          <p>IP: {ticket.ip}</p>
          <p>Navegador: {ticket.browser}</p>
          <p>Estado: {ticket.status}</p>
        </div>
      )}</p>
            <hr/>
          
        </>
    )
}