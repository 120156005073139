import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "../App";
import { CrearTicket } from "./CrearTicket";
import ListaEspera from "./ListaEspera";

export const RouterPage = () => {
  return (
    <Router>
      <Routes>
        <Route path="/crear-ticket" element={<CrearTicket />} />
        <Route path="/lista-espera" element={<ListaEspera />} />

        <Route path="/" element={<ListaEspera />} />
      </Routes>
    </Router>
  );
}