import { SocketProvider } from './context/SocketContext';
import logo from './logo.svg';
import { Col, Row, Typography, List, Card,  Tag} from 'antd'
import { RouterPage } from './pages/RouterPage';
// import css
import './App.css';


const data = [
  {
      ticketNo: 33,
      escritorio: 3,
      agente: 'Fernando Herrera'
  },
  {
      ticketNo: 34,
      escritorio: 4,
      agente: 'Melissa Flores'
  },
  {
      ticketNo: 35,
      escritorio: 5,
      agente: 'Carlos Castro'
  },
  {
      ticketNo: 36,
      escritorio: 3,
      agente: 'Fernando Herrera'
  },
  {
      ticketNo: 37,
      escritorio: 3,
      agente: 'Fernando Herrera'
  },
  {
      ticketNo: 38,
      escritorio: 2,
      agente: 'Melissa Flores'
  },
  {
      ticketNo: 39,
      escritorio: 5,
      agente: 'Carlos Castro'
  },
];
function App() {
  return (
<>
<SocketProvider>
  <RouterPage />
</SocketProvider>
</>
  );
}

export default App;
